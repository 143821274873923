import { makeStyles } from "@mui/styles";

export const smartphoneContentStyles = makeStyles(() => ({
  smartphone_content__root: {
    height: "100vh",
    width: "100vw",
    position: "relative",
    marginTop: 10,
    overflow: "hidden",
    overscrollBehavior: 'contain',

    transition: "opacity ease 0.5s",

    "& .carousel .slide": {
      textAlign: "left",
      // padding: "3rem 5rem 0 1rem",
    },
    "& .slide:nth-child(4)": {
      // padding: "3rem 1rem 0 1rem",
    }
  },

  section__navigation_container: {
    position: "fixed",
    bottom: 32,
    left: "50%",
    transform: "translateX(-50%)",
  },

  smartphone_content__text_container: {
    // height: "100%",
    // minHeight: "100vh",
  },

  smartphone_content__navigation_container: {
    position: "fixed",
    bottom: 32,
    left: "50%",
    right: "50%",
  },
}));
