import { createSlice } from "@reduxjs/toolkit";
import {
  mockedCategories,
  mockedSubcategories,
  mockedSubcategoriesContent,
} from "design-spec/mock";

const initialState = {
  storedData: {
    categories: mockedCategories,
    subcategories: mockedSubcategories,
    subcategoriesContent: mockedSubcategoriesContent,
  },
};

export const dataStoreSlice = createSlice({
  name: "dataStore",
  initialState,

  reducers: {
    updateStore: (state, action) => {
      state.storedData = action.payload.storedData;
    },
  },
});

export const { updateStore } = dataStoreSlice.actions;

export const listenDataStore = (state) => state.dataStore.storedData;

export default dataStoreSlice.reducer;
