import { makeStyles } from "@mui/styles";

export const toogleDrawerButtonStyles = makeStyles(() => ({
  toogle_drawer_button__root: {
    position: "relative",

    "& button": {
      transition: "all ease 0.4s",
      transformOrigin: "top right",
      "&:hover": {
        transform: "scale(105%)",
      },
    },
  },
}));
