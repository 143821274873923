import { makeStyles } from "@mui/styles";

export const sectionMenusStyles = makeStyles(() => ({
  section_menus__root: {
    display: "flex",
    textAlign: "left",
    paddingTop: 50,
    paddingLeft: 15,
  },
  section_menus__categories_container: {
    width: 235,
  },
  section_menus__text_container: {
    padding: "0 70px 0 0 !important",
    width: 435,
    // marginLeft: 45,
  },
}));
