import { makeStyles } from "@mui/styles";
import theme from "mui/theme";

export const tokensAccordionStyles = makeStyles(() => ({
  tokens_accordion__root: {
    position: "relative",

    "& .MuiPaper-root": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  tokens_accordion__inputRow: {
    display: "flex",
    alignItems: "center",
    margin: "5px 0",
    "& p": {
      marginLeft: 15,
    },
  },
}));
