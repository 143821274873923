import { mockedCategories, mockedSubcategories } from "design-spec/mock";

export function findNextCategory(storedEvents) {
  const selectedCategory = storedEvents.selectedCategory;

  if (!selectedCategory) return "1";

  const categoriesListSize = mockedCategories.length;

  if (selectedCategory === categoriesListSize) return;

  const nextCategory = parseInt(selectedCategory) + 1;

  return nextCategory;
}

export function findNextSubcategory(storedEvents) {
  const selectedCategory = storedEvents.selectedCategory;
  const selectedSubcategory = storedEvents.selectedSubcategory;

  const selectedCategorySubs = mockedSubcategories.filter(
    (el) => el.category === selectedCategory
  );

  const subcategoriesListSize = selectedCategorySubs.length;

  const selectedSubcategoryIndex = selectedCategorySubs.findIndex(
    (el) => el.id === selectedSubcategory
  );

  const nextSubcategoryIndex = Number(selectedSubcategoryIndex) + 2;

  if (nextSubcategoryIndex > subcategoriesListSize) {
    return "last";
  }

  const actualNextSubcategoryIndex = Number(selectedSubcategory) + 1;

  return actualNextSubcategoryIndex.toString();
}

export function findScrollNavTarget(storedEvents, direction = "next") {
  const selectedCategory = storedEvents.selectedCategory;
  const selectedSubcategory = storedEvents.selectedSubcategory;

  const selectedCategorySubs = mockedSubcategories.filter(
    (el) => el.category === selectedCategory
  );

  const subcategoriesListSize = selectedCategorySubs.length;

  const selectedSubcategoryIndex = selectedCategorySubs.findIndex(
    (el) => el.id === selectedSubcategory
  );
  // console.debug("selectedSubcategoryIndex", selectedSubcategoryIndex);

  const outOfRangeSubcategoryIndex =
    direction === "next"
      ? Number(selectedSubcategoryIndex) + 2
      : Number(selectedSubcategoryIndex) - 1;

  // console.debug("outOfRangeSubcategoryIndex", outOfRangeSubcategoryIndex);

  if (
    outOfRangeSubcategoryIndex > subcategoriesListSize ||
    outOfRangeSubcategoryIndex < 0
  ) {
    return "outOfRange";
  }

  const actualNextSubcategoryIndex =
    direction === "next"
      ? Number(selectedSubcategory) + 1
      : Number(selectedSubcategory) - 1;

  return actualNextSubcategoryIndex.toString();
}
