import { Fade } from "@mui/material";
import theme from "mui/theme";
import { useState } from "react";
import { ChromePicker } from "react-color";
import { colorFieldButtonStyles } from "./ColorField.styles";

export const ColorField = (props) => {
  const classes = colorFieldButtonStyles();

  const [inputColor, inputColorSet] = useState({
    color: props.color,
  });
  const [displayColorPicker, displayColorPickerSet] = useState(false);

  const handleChange = (color) => {
    inputColorSet({ color: color.hex });
  };

  const handleClick = () => {
    displayColorPickerSet(!displayColorPicker);
  };

  return (
    <div className={classes.color_field__root}>
      <div
        onClick={() => handleClick()}
        className={classes.color_field__button}
        style={{
          backgroundColor: inputColor.color,
          border:
            inputColor.color === theme.palette.secondary.light
              ? `solid 1px ${theme.palette.primary.main}`
              : "none",
        }}
      />

      <Fade in={displayColorPicker} timeout={300}>
        <div className={classes.color_field__popover}>
          <div
            className={classes.color_field__cover}
            onClick={() => handleClick()}
          />
          <ChromePicker
            color={inputColor.color}
            onChange={(color) => handleChange(color)}
          />
        </div>
      </Fade>
    </div>
  );
};
