import { Typography } from "@mui/material";
import { footerStyles } from "./Footer.styles";

export function Footer() {
  const classes = footerStyles();

  return (
    <div className={classes.footer_root}>
      <Typography variant="caption">©2022 Osteopathie Meilen</Typography>
    </div>
  );
}
