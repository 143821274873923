import { forwardRef } from "react";
import clsx from "clsx";

import { Menu } from "features/UI/organisms/menu/Menu";
import { MenuItem } from "features/UI/molecules/menu-item/MenuItem";

import { SectionTitle } from "../title/SectionTitle";
import { Navigation } from "../../navigation/Navigation";
import { Previous } from "features/UI/molecules/navigation/buttons/previous/Previous";
import { Next } from "features/UI/molecules/navigation/buttons/next/Next";

import { sectionCategoryStyles } from "./SectionCategory.styles";

export const SectionCategory = forwardRef((props, ref) => {
  const classes = sectionCategoryStyles();

  const { categories, selectedCategory } = props;

  return (
    <div
      className={clsx(classes.section_category__root)}
      id="section-category"
      ref={ref}
    >
      <SectionTitle>Menü</SectionTitle>
      <Menu compact className={classes.section_category__menu}>
        {categories &&
          categories.map((category) => (
            <div className={`menuItem-${category.id}`}>
              <MenuItem variant="category" id={category.id}>
                {category.title}
              </MenuItem>
            </div>
          ))}
      </Menu>
    </div>
  );
});
