import { makeStyles } from "@mui/styles";

export const tabletContentStyles = makeStyles(() => ({
  tablet_content__root: {
    height: "100vh",
    width: "100vw",
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },

  tablet_content__logo_container: {
    position: "fixed",
    top: 25,
    left: 0,
  },

  tablet_content__navigation_container: {
    position: "fixed",
    bottom: 32,
    left: "50%",
    right: "50%",
  },

  tablet_content__default_content_block: {
    width: 440,
    marginLeft: 272,
    marginRight: "unset",
    textAlign: "left",
  },

  tablet_content__content_block_indented: {
    marginLeft: "unset",
  },

  tablet_content__carousel: {
    position: "relative",
    transform: "translateX(0px)",
    transition: "all ease 0.3s",
  },

  tablet_content__partial_carousel: {
    position: "fixed",
    transform: "translateX(-240px)",
    transition: "all ease 0.3s",
  },

  tablet_content__content_constructor_container: {
    paddingLeft: 252,
  },
}));
