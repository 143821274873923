import { makeStyles } from "@mui/styles";
import THEME_TOKENS from "mui/theme-tokens.json";

export const textBlockStyles = makeStyles((theme) => ({
  text_block__root: {
    height: "96vh",
    padding: "3rem 5rem 1rem 1rem"
  },

  text_block__touch_div: {
    position: "absolute",
    inset: '0',
  },

  text_block__cms_styled: {
    "& p": {
      marginTop: "unset",
      marginBottom: 10,

      fontFamily: "AvenirNextMedium",
      fontStyle: theme.typography.allVariants.fontStyle,
      fontWeight: theme.typography.allVariants.fontWeight,
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
      letterSpacing: theme.typography.body1.letterSpacing,
      paragraphSpacing: theme.typography.body1.paragraphSpacing,
      opacity: theme.typography.body1.opacity,

      maxWidth: THEME_TOKENS.spacing.contentColumnMaxWidth.desktop - 50,

      "& a": {
        textDecoration: THEME_TOKENS.typography.allVariants.links.textDecoration,
        color: theme.palette.primary.dark,
        transition: `color ease 0.4s`,
        "&:hover": {
          color: theme.palette.primary.hoveredLink,
        },
      },
    },

    "& h3": {
      marginTop: 35,
      marginBottom: 11,

      fontFamily: "AvenirNextMedium",
      fontStyle: theme.typography.allVariants.fontStyle,
      fontWeight: theme.typography.allVariants.fontWeight,
      fontSize: theme.typography.subtitle1.fontSize,
      lineHeight: theme.typography.subtitle1.lineHeight,
      letterSpacing: theme.typography.subtitle1.letterSpacing,
      paragraphSpacing: theme.typography.subtitle1.paragraphSpacing,
      opacity: theme.typography.subtitle1.opacity,

      maxWidth: 235,
    },

    "& ul": {
      marginTop: "unset",
      marginBottom: 35,
      paddingLeft: 0,
      maxWidth: 430,

      listStyleType: "none",
      "& li": {
        backgroundImage: "url(/assets/svgs/Bulletpoint.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0 9px",
        paddingLeft:
          THEME_TOKENS.typography.allVariants.lists.spaceBetweenBulletAndText,

        marginBottom: 5,
        opacity: theme.typography.body1.opacity,
      },
    },

    "& img": {
      minWidth: THEME_TOKENS.spacing.contentTextMaxWidth.desktop,
      width: "40vw",
      height: "auto",
      marginBottom: 15,
    },
  },

  text_block__large: {
    paddingRight: "unset",
  },

  text_block__hidden: {
    display: "none",
  },
}));
