import { makeStyles } from "@mui/styles";

export const homeStyles = makeStyles((theme) => ({
  home__root: {
    // backgroundColor: theme.palette.primary.main,
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
