import { createSlice } from "@reduxjs/toolkit";
import { PAGES } from "features/utils/constants/pages";

const initialState = {
  currentPage: PAGES.home,
  mobileSection: "",
  loading: false,
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,

  reducers: {
    updateCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    updateSmartphoneSection: (state, action) => {
      state.mobileSection = action.payload;
    },
  },
});

export const { updateCurrentPage, updateSmartphoneSection } =
  navigationSlice.actions;

export const selectCurrentPage = (state) => state.navigation.currentPage;

export const selectCurrentSection = (state) => state.navigation.mobileSection;

export default navigationSlice.reducer;
