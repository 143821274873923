import { makeStyles } from "@mui/styles";
import theme from "../../../../../mui/theme";

export const blobStyles = makeStyles(() => ({
  blob__root: {
    width: 130,
    height: 130,

    "& svg": {
      transition: "transform ease 0.5s",
    },

    "& svg path": {
      fill: theme.palette.secondary.blob,
      opacity: 0.9,
    },

    "&:hover": {
      "& svg": {
        transform: "scale(1.1, 1.1)",
      },
      "& a": {
        opacity: 1,
      },
    },
  },
  blob__button: {
    cursor: "pointer",
  },
  blob__text_container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: 1,

    "& p": {
      color: theme.palette.primary.light,
    },
  },
}));
