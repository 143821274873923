import { makeStyles } from "@mui/styles";

export const sectionSubcategoryStyles = makeStyles(() => ({
  section_subcategory__root: {},
  section__navigation_container: {
    position: 'absolute',
    bottom: 32,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  section_subcategory__menu: {
    marginLeft: '1rem',
  }
}));
