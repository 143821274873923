import { makeStyles } from "@mui/styles";

export const colorFieldButtonStyles = makeStyles(() => ({
  color_field__root: {},
  color_field__button: {
    width: 30,
    height: 30,
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",

    transition: "all ease 0.3s",

    "&:hover": {
      transform: "scale(120%)",
    },
  },
  color_field__popover: {
    position: "absolute",
    zIndex: 3,
  },
  color_field__cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
}));
