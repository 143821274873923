import { forwardRef } from "react";

import { Button } from "@mui/material";
import { toogleDrawerButtonStyles } from "./ToogleDrawerButton.styles";

export const ToogleDrawerButton = forwardRef((props, ref) => {
  const classes = toogleDrawerButtonStyles();

  return (
    <div className={classes.toogle_drawer_button__root} ref={ref}>
      <Button onClick={props.onClick}>EDIT THEME</Button>
    </div>
  );
});
