import { makeStyles } from "@mui/styles";

export const sectionTitleStyles = makeStyles(() => ({
  section_title__root: {
    width: '100%',
    height: '3rem',

    textAlign: "center",
  },
  section_title__text: {
    maxWidth: '70vw',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block'
  }
}));
