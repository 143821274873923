import { OverlayInner } from "features/UI/atoms/content/image/overlay-inner/OverlayInner";

import { ReactComponent as SvgPicture1 } from "design-spec/assets/svg/Angebote.svg";
import { ReactComponent as SvgPicture2 } from "design-spec/assets/svg/Ihre_Behandlung.svg";
import { ReactComponent as SvgPicture3 } from "design-spec/assets/svg/Uber_mich.svg";
import { ReactComponent as SvgPicture4 } from "design-spec/assets/svg/Kontakt.svg";

import { overlayStyles } from "./Overlay.styles";

export function Overlay({ children }) {
  const classes = overlayStyles();

  const images = [
    {
      id: "1",
      svg: <SvgPicture1 />,
    },
    {
      id: "2",
      svg: <SvgPicture2 />,
    },
    {
      id: "3",
      svg: <SvgPicture3 />,
    },
    {
      id: "4",
      svg: <SvgPicture4 />,
    },
  ];

  return (
    <div className={classes.overlay__root}>
      {images.map((image) => (
        <div key={`overlayInner-${image.id}`}>
          <OverlayInner image={image} />
        </div>
      ))}
    </div>
  );
}
