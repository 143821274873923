import { makeStyles } from "@mui/styles";

export const desktopHomeStyles = makeStyles((theme) => ({
  desktop_home__root: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  desktop_home__logo_container: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  desktop_home__image: {
    width: "auto",
    height: "80vh",
  },
}));
