import { makeStyles } from "@mui/styles";
import theme from "mui/theme";

export const closeButtonStyles = makeStyles(() => ({
  close_button__root: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 10,
    minHeight: 60,

    "& svg": {
      cursor: "pointer",

      fill: theme.palette.primary.dark,
      fontSize: 30,
      transition: "all ease 0.4s",

      "&:hover": {
        fontSize: 35,
      },
    },
  },
}));
