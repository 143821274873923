import { makeStyles } from "@mui/styles";

export const nextStyles = makeStyles(() => ({
  next_root: {
    margin: "22px 3px 0px",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },

  next_text: {
    position: "absolute",
    left: 40,
  },
}));
