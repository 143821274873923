import { makeStyles } from "@mui/styles";
import theme from "mui/theme";

export const themeEditorStyles = makeStyles(() => ({
  theme_editor__root: {
    overflow: "hidden",
    position: "absolute",
    top: 0,
    right: 0,

    "& button": {
      fontSize: 20,
      position: "absolute",
      top: 20,
      right: 20,
    },
  },

  theme_editor__drawer: {
    position: "relative",
    zIndex: 2,
    width: "25vw",
    height: "100vh",

    padding: "0 15px",

    background: "rgba(255, 160, 96, 0.1)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(14.7px)",
    webkitBackdropFilter: "blur(14.7px)",
    border: "1px solid rgba(255, 160, 96, 0.3)",
  },

  theme_editor__close: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 10,
    minHeight: 60,

    "& svg": {
      cursor: "pointer",

      fill: theme.palette.primary.dark,
      fontSize: 30,
      transition: "all ease 0.4s",

      "&:hover": {
        fontSize: 35,
      },
    },
  },

  theme_editor__form_container: {
    marginTop: 20,
  },
}));
