import { makeStyles } from "@mui/styles";
import theme from "mui/theme";

export const carouselStyles = makeStyles(() => ({
  carousel__root: {
    overflow: "hidden",
  },

  carousel__image: {
    position: "absolute",
    bottom: "11vh",
    left: 0,

    transition: "opacity ease 2s",
    opacity: 0,

    "& svg": {
      width: "100vw",
      height: "auto",
    },

    [theme.breakpoints.only("tablet")]: {
      "& svg": {
        maxHeight: "70vh",
      },
    },
  },
}));
