import { makeStyles } from "@mui/styles";
import theme from "mui/theme";

export const arrowStyles = makeStyles(() => ({
  arrow_root: {
    cursor: "pointer",
    transition: "opacity ease 0.2s",
    opacity: 0.2,

    "& svg": {
      fill: theme.palette.primary.dark,
    },
    [theme.breakpoints.only('desktop')]: {    
      "&:hover": {
        opacity: 0.8,
      },
    }
  },
  arrow_previous: {
    "& svg": {
      rotate: "180deg",
    },
  },
}));
