import { makeStyles } from "@mui/styles";

export const tabletHomeStyles = makeStyles(() => ({
  tablet_home__root: {
    height: "100vh",
    width: "100vw",
    position: "relative",
  },

  tablet_home__logo_container: {
    position: "fixed",
    top: 25,
    left: 0,
  },
  
  tablet_home__navigation_container: {
    position: "fixed",
    bottom: 32,
    left: "50%",
    right: "50%",
    zIndex: 99,
  },
}));
