import { forwardRef } from "react";
import clsx from "clsx";

import { Menu } from "features/UI/organisms/menu/Menu";
import { MenuItem } from "features/UI/molecules/menu-item/MenuItem";
import { ContentWrapper as ContentWrapperStyles } from "features/UI/molecules/content/wrapper/text-block/ContentWrapper";

import { SectionTitle } from "../title/SectionTitle";

import { sectionMenusStyles } from "./SectionMenus.styles";
import { TextBlock } from "features/UI/molecules/content/text-block/TextBlock";
import { mockedDefaultContentBlock } from "design-spec/mock";
import { findElementPositionById } from "features/utils/DOM/findElementPositionById";

export const SectionMenus = forwardRef((props, ref) => {
  const classes = sectionMenusStyles();

  const { categories, selectedCategory, subcategories, selectedSubcategory } =
    props;

  const subcategoryMenuTopPositioning = () => {
    if (!selectedCategory) return 0;

    const categoryMenuItemPosition = findElementPositionById(
      `menuItem-category-${selectedCategory}`
    );

    if (!categoryMenuItemPosition) return 0;

    const menuItemTop = categoryMenuItemPosition.top - 50;

    return menuItemTop;
  };

  return (
    <div
      className={clsx(classes.section_menus__root)}
      id="section-menus"
      ref={ref}
    >
      <Menu compact className={classes.section_menus__categories_container}>
        {categories &&
          categories.map((category) => (
            <div className={`menuItem-${category.id}`}>
              <MenuItem variant="category" id={category.id}>
                {category.title}
              </MenuItem>
            </div>
          ))}
      </Menu>
      {selectedCategory === "" ? (
        <TextBlock className={classes.section_menus__text_container}>
          {mockedDefaultContentBlock("tablet")}
        </TextBlock>
      ) : (
        subcategories.length > 0 &&
        (!subcategories[0].content ? (
          <div
            style={{
              marginTop: subcategoryMenuTopPositioning(),
            }}
          >
            <Menu className={classes.section_subcategory__menu}>
              {subcategories &&
                subcategories.map((subcategory) => (
                  <MenuItem variant="subcategory" id={subcategory.id}>
                    {subcategory.title}
                  </MenuItem>
                ))}
            </Menu>
          </div>
        ) : (
          <ContentWrapperStyles smartphone>
            <div
              style={{
                marginTop: subcategoryMenuTopPositioning(),
              }}
              dangerouslySetInnerHTML={{
                __html: subcategories[0].content,
              }}
            ></div>
          </ContentWrapperStyles>
        ))
      )}
    </div>
  );
});
