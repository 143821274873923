import { Menu } from "features/UI/organisms/menu/Menu";
import { Footer } from "features/UI/organisms/footer/Footer";

import { MenuItem } from "features/UI/molecules/menu-item/MenuItem";
import { Overlay } from "features/UI/molecules/content/overlay-image/Overlay";
import { ContentWrapper as ContentWrapperStyles } from "features/UI/molecules/content/wrapper/text-block/ContentWrapper";
import { CategoryContent } from "features/UI/molecules/content/category-content/CategoryContent";
import { TitleLikeBlock } from "features/UI/molecules/content/title-like-block/TitleLikeBlock";

import { Logo } from "features/UI/atoms/svgs/logo/Logo";

import { Fade } from "@mui/material";
import theme from "mui/theme";

import { findElementPositionById } from "features/utils/DOM/findElementPositionById";

import { mockedDefaultContentBlock } from "design-spec/mock";
import { desktopContentStyles } from "./DesktopContent.styles";
import { ContentConstructor } from "features/UI/organisms/content/content-constructor-desktop/ContentConstructor";

const timeout = theme.transitions.fade.textsTimeout;

export function DesktopContent({ cmsData }) {
  const classes = desktopContentStyles();

  const { categories, subcategories, selectedCategory, selectedSubcategory } =
    cmsData;

  const subcategoryMenuTopPositioning = () => {
    if (!selectedCategory) return 0;

    const categoryMenuItemPosition = findElementPositionById(
      `menuItem-category-${selectedCategory}`
    );

    if (!categoryMenuItemPosition) return 0;

    const menuItemTop = categoryMenuItemPosition.top - 30;

    return menuItemTop;
  };

  return (
    <>
      <div className={classes.desktop_content__root}>
        <Overlay />
        <Fade in timeout={timeout}>
          <div className={classes.desktop_content__logo_container}>
            <Logo small />
          </div>
        </Fade>

        <div className={classes.desktop_content__main_container}>
          <Fade in timeout={timeout}>
            <div className={classes.desktop_content__left_column}>
              <div className={classes.desktop_content__menu_grid_column}>
                <Menu compact>
                  {categories &&
                    categories.map((category) => (
                      <div key={`categoryMenuItem-${category.id}`}>
                        <MenuItem variant="category" id={category.id}>
                          {category.title}
                        </MenuItem>
                      </div>
                    ))}
                </Menu>
              </div>
              {selectedCategory === "" ? (
                <div
                  className={classes.desktop_content__default_content_column}
                >
                  <TitleLikeBlock>{mockedDefaultContentBlock()}</TitleLikeBlock>
                </div>
              ) : null}

              <div
                style={{
                  top: subcategoryMenuTopPositioning(),
                }}
                className={classes.desktop_content__menu_grid_column}
              >
                {selectedCategory && (
                  <Menu>
                    {subcategories &&
                      subcategories.map((subcategory) => (
                        <div key={`categoryMenuItem-${subcategory.id}`}>
                          {subcategory.content === "" ? (
                            <MenuItem variant="subcategory" id={subcategory.id}>
                              {subcategory.title}
                            </MenuItem>
                          ) : (
                            <CategoryContent>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: subcategory.content,
                                }}
                              />
                            </CategoryContent>
                          )}
                        </div>
                      ))}
                  </Menu>
                )}
              </div>
            </div>
          </Fade>

          {selectedSubcategory && (
            <div className={classes.desktop_content__content_container}>
              <ContentWrapperStyles>
                <ContentConstructor desktop />
              </ContentWrapperStyles>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}
