import { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";

import { Fade } from "@mui/material";
import theme from "mui/theme";

import { contentWrapperStyles } from "./ContentWrapper.styles";
import { useSelector } from "react-redux";
import {
  listenEventBroker,
  listenStoredEvents,
} from "features/event-driver/broker/eventBrokerSlice";
import { findElementPositionById } from "features/utils/DOM/findElementPositionById";

const timeout = theme.transitions.fade.textsTimeout;

export const ContentWrapper = forwardRef((props, ref) => {
  const classes = contentWrapperStyles();

  const eventBroker = useSelector(listenEventBroker);

  const [visible, visibleSet] = useState(true);

  useEffect(() => {
    visibilityToogle();
  }, [eventBroker.eventValue]);

  const visibilityToogle = () => {
    if (eventBroker.eventSource === "MenuItem") {
      if (eventBroker.eventType === "hover") {
        if (eventBroker.eventValue !== "") {
          visibleSet(false);
          return;
        }
      }
    }
    visibleSet(true);
    return;
  };

  return (
    <div
      className={clsx(
        classes.content_wrapper__root,
        props.smartphone && classes.content_wrapper__smartphone
      )}
    >
      <Fade timeout={timeout} in={visible}>
        <div>{props.children}</div>
      </Fade>
    </div>
  );
});
