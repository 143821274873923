import { makeStyles } from "@mui/styles";

export const submitButtonStyles = makeStyles(() => ({
  submit_button__root: {
    position: "absolute",
    width: "100%",

    "& button": {
      top: 10,
      left: 0,

      transition: "all ease 0.4s",
      transformOrigin: "top left",
      "&:hover": {
        transform: "scale(105%)",
      },
    },
  },
}));
