import { makeStyles } from "@mui/styles";
import THEME from "mui/theme-tokens.json";

export const contentConstructorStyles = makeStyles(() => ({
  content_constructor__root: {
    height: "100%",
    width: THEME.spacing.contentColumnMaxWidth.desktop,
    position: "relative",
  },

  content_constructor__desktop: {
    left: "100%",
  },

  content_constructor__tablet: {
    left: "unset",
  },

  content_constructor__tablet: {
    left: "unset",
  },

  content_constructor__smartphone: {
    width: "91vw",
  },

  content_constructor__content_wrapper: {
    opacity: 0,
    transition: THEME.transitions.cssStyles.opacity,
    position: "absolute",
  },

  content_constructor__fake_bottom: {
    height: "200vh",
  },

  content_constructor__fake_bottom_small: {
    height: 410,
  },
}));
