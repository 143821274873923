import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Fade,
  Slide,
} from "@mui/material";
import { themeEditorStyles } from "./ThemeEditor.styles";
import { ToogleDrawerButton } from "features/UI/atoms/theme-editor/buttons/ToogleDrawer/ToogleDrawerButton";
import { CloseButton } from "features/UI/atoms/theme-editor/buttons/Close/CloseButton";
import { TokensAccordion } from "features/UI/molecules/theme-editor/accordion/TokensAccordion";
import { SubmitButton } from "features/UI/atoms/theme-editor/buttons/Submit/SubmitButton";

const ThemeEditor = () => {
  const classes = themeEditorStyles();

  const [openedDrawer, openedDrawerSet] = useState(false);

  const [openDialog, openDialogSet] = useState(false);

  const handleDialogOpen = () => {
    openDialogSet(true);
  };

  const handleClose = () => {
    openDialogSet(false);
  };

  const drawerOpeningHandler = () => {
    openedDrawerSet(!openedDrawer);
    return;
  };

  return (
    <div className={classes.theme_editor__root}>
      <Fade in={!openedDrawer} timeout={500}>
        <ToogleDrawerButton onClick={drawerOpeningHandler} />
      </Fade>

      <Slide direction={"left"} in={openedDrawer} timeout={500}>
        <div className={classes.theme_editor__drawer}>
          <SubmitButton onClick={handleDialogOpen} />
          <CloseButton onClick={drawerOpeningHandler} />

          <div className={classes.theme_editor__form_container}>
            <TokensAccordion />
          </div>
        </div>
      </Slide>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This functionality is not ready yet. Soon you will be able to edit
            the entire theme of the site.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ThemeEditor;
