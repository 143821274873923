import { useDispatch } from "react-redux";
import {
  updateCurrentPage,
  updateSmartphoneSection,
} from "features/event-driver/navigation/navigationSlice";

import { Arrow } from "features/UI/atoms/navigation/arrow/Arrow";

import { Typography } from "@mui/material";

import { nextStyles } from "./Next.styles";

export function Next(props) {
  const classes = nextStyles();

  const { destiny, section, whitoutText, scrollTo, onClick } = props;

  const dispatch = useDispatch();

  const navigate = () => {
    if (destiny) {
      dispatch(updateCurrentPage(destiny));
    }
    if (section) {
      dispatch(updateSmartphoneSection(section));
      // scrollToMobileSection();
    }
  };

  function scrollToMobileSection() {
    const scrollableEl = document.getElementById("smartphone-scrollable-div");
    if (scrollTo) {
      scrollableEl.scrollTo(scrollTo, 0);
    }
  }

  return (
    //Don't remove id="next-button" or touch funcions will break
    <div className={classes.next_root}>
      <div onClick={onClick} id="next-button">
        <Arrow direction="next" />
      </div>

      {!whitoutText && (
        <Typography variant="caption" className={classes.next_text}>
          Weiter
        </Typography>
      )}
    </div>
  );
}
