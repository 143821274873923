import { forwardRef } from "react";
import clsx from "clsx";
import { Fade } from "@mui/material";

import theme from "mui/theme";
import { textBlockStyles } from "./TextBlock.styles";

const timeout = theme.transitions.fade.imagesTimeout;

export const TextBlock = forwardRef((props, ref) => {
  const classes = textBlockStyles();

  return (
    <>
      <Fade in timeout={1000}>
        <div
          className={clsx(
            classes.text_block__root,
            props.large ? classes.text_block__large : null,
            props.cmsStyled ? classes.text_block__cms_styled : null,
            props.className
          )}
        >
          <div>{props.children}</div>
        </div>
      </Fade>
    </>
  );
});
