let initialX = null;
let initialY = null;

let nextButtonElement;
let previousButtonElement;

// Takes e.touches[0]
export function startTouch(touchObject) {
  initialX = touchObject.clientX;
  initialY = touchObject.clientY;
}

// Takes e.changedTouches[0]
export function moveTouch(touchObject) {
  
  if (initialX === null) {
    return;
  }
  
  if (initialY === null) {
    return;
  }

  const currentX = touchObject.clientX;
  const currentY = touchObject.clientY;

  let diffX = initialX - currentX;
  let diffY = initialY - currentY;

  if (Math.abs(diffX) > Math.abs(diffY)) {
    // sliding horizontally
    if (diffX > 0) {
      // Swiped left
      nextButtonElement = document.getElementById("next-button");
      if (nextButtonElement) {
        nextButtonElement.click();
      }
    } else {
      // Swiped right
      previousButtonElement = document.getElementById("previous-button");
      if (previousButtonElement) {
        previousButtonElement.click();
      }
    }
  }

  initialX = null;
  initialY = null;
}

// Takes e.touches[0]
export function detectMoveTouchDirection(touchObject) {

  if (initialX === null) {
    return;
  }
  
  if (initialY === null) {
    return;
  }

  const currentX = touchObject.clientX;
  const currentY = touchObject.clientY;

  const diffX = initialX - currentX;
  const diffY = initialY - currentY;

  if (Math.abs(diffX) > Math.abs(diffY)) {
    if (diffX > 0) {
      return "left";
    } else {
      return "right";
    }
  }

  initialX = null;
  initialY = null;
}
