import { forwardRef, useEffect, useState } from "react";

import { ContentWrapper as ContentWrapperStyles } from "features/UI/molecules/content/wrapper/text-block/ContentWrapper";

import { SectionTitle } from "../title/SectionTitle";

import { Slide } from "@mui/material";
import THEME from "mui/theme-tokens.json";
import { sectionContentStyles } from "./SectionContent.styles";

const token_textsTimeout = THEME.transitions.slide.textsTimeout;
const slideTimeout = token_textsTimeout;

export const SectionContent = forwardRef((props, ref) => {
  const classes = sectionContentStyles();

  const [sectionTitle, sectionTitleSet] = useState("");

  const {
    categories,
    selectedCategory,
    subcategories,
    selectedSubcategory,
    content,
  } = props;

  useEffect(() => {
    if (selectedCategory && selectedSubcategory) {
      buildSectionTitle();
    }
  }, [selectedCategory, selectedSubcategory]);

  function buildSectionTitle() {
    const categoryName = categories?.find(
      (category) => category.id === selectedCategory
    );

    if (!categoryName) return;

    const subcategoryName = subcategories?.find(
      (subcategory) => subcategory.id === selectedSubcategory
    );

    let title = "";
    if (categoryName && subcategoryName) {
      title = `${categoryName.title} / ${subcategoryName.title}`;
    }

    sectionTitleSet(title);
    return;
  }

  return (
    <Slide direction="left" in timeout={slideTimeout}>
      <div className={classes.section_subcategory__root}>
        <SectionTitle>{sectionTitle}</SectionTitle>

        <ContentWrapperStyles smartphone>
          <div
            id={`content-${content?.id}`}
            dangerouslySetInnerHTML={{
              __html: content?.content,
            }}
          ></div>
        </ContentWrapperStyles>
      </div>
    </Slide>
  );
});
