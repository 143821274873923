import clsx from "clsx";
import { ReactComponent as RightArrow } from "./assets/right-arrow.svg";

import { arrowStyles } from "./Arrow.styles";

export function Arrow({ direction }) {
  const classes = arrowStyles();

  return (
    <div
      className={clsx(
        classes.arrow_root,
        direction === "previous" && classes.arrow_previous
      )}
    >
      <RightArrow title={`Go to ${direction} page`} />
    </div>
  );
}
