import { makeStyles } from "@mui/styles";

export const sectionContentStyles = makeStyles(() => ({
  section_content__root: {},

  section__navigation_container: {
    position: "absolute",
    bottom: 32,
    left: "50%",
    transform: "translateX(-50%)",
  },
}));
