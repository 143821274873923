const validPaths = ["/", "/theme"];

export function validadeRoute() {
  const path = window.location.pathname;

  if (validPaths.includes(path)) {
    return;
  }

  window.location = "/";
  return;
}

export function checkCurrentPath(path) {
  const currentPath = window.location.pathname;

  if (currentPath === path) {
    return true;
  }

  return false;
}
