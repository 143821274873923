import { Paragraph } from "features/UI/atoms/content/paragraph/Paragraph";

import { ReactComponent as SvgPicture1 } from "./assets/svg/Home.svg";
import { ReactComponent as SvgPicture2 } from "./assets/svg/Angebote.svg";
import { ReactComponent as SvgPicture3 } from "./assets/svg/Ihre_Behandlung.svg";
import { ReactComponent as SvgPicture4 } from "./assets/svg/Uber_mich.svg";
import { ReactComponent as SvgPicture5 } from "./assets/svg/Kontakt.svg";

export const mockedDefaultContentBlock = (screen = "desktop") => {
  const p1 =
    "Die Praxis für Menschen, die ihre Gesundheit mitbestimmen möchten";
  const p2 =
    "Wir helfen Ihnen möglichst ohne Schmerzen und Beschwerden zu leben – so selbstbestimmtund gesund wie möglich. Wir bieten eine ganzheitliche und individuelle Behandlung fürakute und chronische Leiden auf Basis aktuellster medizinischer Erkenntnisse.";
  return (
    <>
      <Paragraph variant="h1">{p1}</Paragraph>
      <Paragraph variant={screen === "smartphone" ? "body1" : "h1"}>
        {p2}
      </Paragraph>
    </>
  );
};

export const mockedCategoryContent = () => {
  return (
    <>
      <Paragraph variant="body1">
        Mein Name ist Barbara Tischhauser, ich bin GDK dipl. Osteopathin und
        Vizepräsidentin des{" "}
        <a href="/"> Schweizerischen Verbands der Osteopathen </a> (svo-fso).
        Eine erfolgreiche Behandlung bedeutet für mich, dass Menschen sich
        gehört und verstanden fühlen und besser spüren, was ihnen guttut.
      </Paragraph>
      <Paragraph variant="body1">
        Nach meinem fünfjährigen Studium in Lausanne (2009) war ich
        Miteigentümerin der Gruppenpraxis Osteopathie Zentrum Biel. Ich
        organisiere laufend Weiterbildungen in medizinischen und osteopathischen
        Fachgebieten und begleite Studenten*innen auf die Abschlussprüfung vor.
      </Paragraph>
      <Paragraph variant="body1">
        Ich bin in Erlenbach aufgewachsen und lebe mit meiner Familie in Meilen.
        In der Freizeit praktiziere ich Kickboxen, Pilates, Wandern und Klavier.
      </Paragraph>
    </>
  );
};

export const mockedCategories = [
  {
    id: "1",
    title: "Angebote",
    content: "",
  },
  {
    id: "2",
    title: "Ihre Behandlung",
    content: "",
  },
  {
    id: "3",
    title: "Über mich/uns",
  },
  {
    id: "4",
    title: "Kontakt",
  },
];

export const mockedSubcategories = [
  {
    id: "1",
    category: "1",
    title: "Allgemeine Osteopathie",
    content: "",
  },
  {
    id: "2",
    category: "1",
    title: "Osteopathie für Neugeborene und Kinder",
    content: "",
  },
  {
    id: "3",
    category: "1",
    title: "Osteopathie rund um den Beckenboden für Männer*Frauen",
    content: "",
  },
  {
    id: "4",
    category: "1",
    title: "In der Schwangerschaft und im Wochenbett",
    content: "",
  },
  {
    id: "5",
    category: "1",
    title: "Osteopathie für Sportler:innen und Künstler:innen",
    content: "",
  },
  {
    id: "6",
    category: "1",
    title: "Kiefer-beschwerden, Kopfschmerzen und Migräne",
    content: "",
  },
  {
    id: "7",
    category: "2",
    title: "Wie verläuft die Erstkonsultation?",
    content: "",
  },
  {
    id: "8",
    category: "2",
    title: "Wie verläuft eine osteopathische Behandlung?",
    content: "",
  },
  {
    id: "9",
    category: "2",
    title: "Wie werden Beschwerden nachhaltig behandelt?",
    content: "",
  },
  {
    id: "10",
    category: "2",
    title: "Zusatz- und Komplementärver&shy;sicherung und Unfälle",
    content: "",
  },
  {
    id: "11",
    category: "2",
    title: "Osteopathische Leistungen und Kosten",
    content: "",
  },
  {
    id: "12",
    category: "3",
    title: "",
    content: `
      <h3 style="margin-top: 2px">Barbara Tischhauser</h3>
      <p>Ich bin GDK dipl. Osteopathin, assistierende Dozentin an der <a target="_blank" href="https://www.ffhs.ch/de">Fernfachhochschule Schweiz
      (FFHS)</a> im Studiengang Osteopathie und ehem. Vizepräsidentin des Schweizerischen
      Osteopathieverbands (SuisseOsteo) von 2016-2022.
      </p>
      <p>Eine erfolgreiche Behandlung bedeutet für mich, dass Menschen sich gehört und verstanden fühlen und wir gemeinsam herausfinden, was Ihnen gut tut.
      </p>
      <p>Nach meinem fünfjährigen Studium in Lausanne (2009) war ich Miteigentümerin der
      Gruppenpraxis <a target="_blank" href="https://osteopathiezentrumbiel.ch">Osteopathie Zentrum Biel.</a> Ich beteilige mich laufend an Weiterbildungen in
      medizinischen und osteopathischen Fachgebieten und begleite Studierende auf die
      <a target="_blank" href="https://www.gdk-cds.ch/de/gesundheitsberufe/osteopathie">eidgenössische Abschlussprüfung</a> vor.
      </p>
      <p>Ich bin in Erlenbach aufgewachsen und lebe mit meiner Familie in Meilen. In der Freizeit
      praktiziere ich Kickboxen, Snowboarden, Wandern und Klavier.
      </p>
      `,
  },
  {
    id: "13",
    category: "4",
    title: "",
    content: `
    <p style="margin-top: 1px; margin-bottom: 0px">Fragen zur osteopathischen</p>
    <p style="margin: unset">Behandlung beantworte</p>
    <p style="margin-top: unset">ich gerne per Telefon oder Mail:</p>
    <p>
    
    <a target="_blank" href="https://www.google.com/maps/place/Osteopathie+Meilen/@47.2686906,8.6407288,15z/data=!4m5!3m4!1s0x0:0x435a717cd3a28d86!8m2!3d47.2686906!4d8.6407288">
    Osteopathie Meilen GmbH</br>
    Lämmliweg 14 </br>
    8706 Meilen</a>
    </br>
    
    <a href="mailto:praxis@osteopathie-meilen.ch">praxis@osteopathie-meilen.ch</a></br>
    <a href="tel:+41438439053">+41 43 843 90 53</a>
    </p>
    <p class="contact_caption_text">Vereinbarte Termine können kostenfrei bis spätestens 24 Stunden vor der Konsultation
    telefonisch abgesagt werden. Kurzfristige Absagen oder nicht entschuldigte Konsultationen
    werden Ihnen privat in Rechnung gestellt.
    </p>
    `,
  },
];

export const mockedSubcategoriesContent = [
  {
    id: '1',
    subcategory: '1',
    content: `<p>Die osteopathische Behandlung ist eine personenzentrierte Therapie und sucht nach dem
      Ursprung Ihrer Beschwerde. Wir lindern Symptome nachhaltig, indem wir Körper, Psyche
      und Ihre individuelle Lebensweise ganzheitlich betrachten. Im Patientengespräch und in der
      klinischen Untersuchung identifizieren wir mögliche Blockaden. Dank manueller Techniken
      lösen wir Spannungen in Gelenken und im Bindegewebe und verbessern die Beweglichkeit
      und Durchblutung.</p>

      <h3>Häufige Behandlungsgründe:</h3>

      <ul>
        <li>Akute und chronische Gelenksblockaden: Rücken, Nacken, Knie, Schultern</li>
        <li>Verdauungsprobleme, Blähung, Verstopfung</li>
        <li>Kopfschmerzen, Migräne</li>
        <li>Schlafstörungen, Burnout-Syndrom, postpartale Depression</li>
        <li>Osteopathie nach einer Operation, Narbenbehandlung</li>
      </ul>
      
      <img src="/assets/images/1.jpg"/>

      `
  },
  {
    id: '2',
    subcategory: '2',
    content: `
      <p>Bei jeder Geburt wiederholt sich das Wunder des Lebens. Wir unterstützen Ihr Baby parallel
      zu den ärztlichen Routinekontrollen bereits in den ersten Monaten beim Stillen, Krabbeln,
      Schlafen und Verdauen.</p>
      <p>Durch eine genaue Kenntnis der Entwicklungsschritte und durch
      gezielte Entspannungstechniken reduziert die Osteopathie mögliche Wachstumsschmerzen,
      Fehlhaltungen wie Skoliose, oder auch Konzentrationsprobleme in der Pubertät.</p>

      <h3>Häufige Konsultationsgründe bei Kindern und Jugendlichen:</h3>
      <ul>
        <li>Schiefhals und Plagiozephalie, auch parallel zur Helmtherapie</li>
        <li>Stillprobleme, Koliken und Verstopfung</li>
        <li>Schlafstörung und Unruhe (Schreibabys)</li>
        <li>Wiederkehrende Mittelohrentzündung, Bronchitis</li>
        <li>Reizdarm, Zöliakie.</li>
      </ul>

      <img src="/assets/images/2.jpg"/>
      `
  },
  {
    id: '3',
    subcategory: '3',
    content: `<p>Eine Reizblase, schmerzhafte Menstruation oder eine gestörte Sexualfunktion beschränken 
      den Alltag erheblich. Parallel zu einer gynäkologischen oder urologischen Abklärung hilft die
      Osteopathie die Durchblutung im kleinen Becken zu fördern und Gewebespannungen zu
      lösen. Mittels Diagnose können gezielte Beckenübungen oder vaginale wie auch rektale
      osteopathische Techniken eingesetzt werden.</p>

      <h3>Häufige Behandlungsgründe im Beckenbodenbereich:</h3>

      <ul>
        <li>Steissbeinschmerzen, Schambeinentzündung (Sturz, Postpartum, Fussball)      </li>
        <li>Beckenbodenschmerzen (Episiotomie)</li>
        <li>Menstruationsbeschwerden (Endometriose, Amenorrhoe)</li>
        <li>Schmerzen beim Geschlechtsverkehr</li>
        <li>Blaseninkontinenz, wiederkehrende Blasenentzündung</li>
        <li>Unerfüllter Kinderwunsch, wiederholte Fehlgeburt</li>
        <li>Schmerzen im Prostata- und Hodenbereich</li>
      </ul>

      <p>Die Praxis Osteopathie Meilen oder <a target="_blank" href="https://www.fso-svo.ch/de_CH/">der Schweizer Osteopathieverband (SuisseOsteo)</a>
      informiert weiter über interne Beckenboden-Techniken und besitzt eine Liste vonqualifizierten Therapeuten:innen.</p>
      `
  },
  {
    id: '4',
    subcategory: '4',
    content: `<p>Die Schwangerschaft ist eine Zeit der Umstellung. Das Körpergefühl der Frau verändert sich.
      Die Muskeln, Bänder und Faszien sind nach der hormonellen Umstellung elastischer. Bei
      Beschwerden lösen wir die Spannungen im Gewebe sorgfältig und ohne Medikamente. Wir
      informieren über die Geburtsvorbereitung, Rückbildung und Nachsorge und sind für Ihre
      Fragen, Zweifel oder Ängste da.</p>

      <h3>Häufige Konsultationsgründe in der Schwangerschaft und im Postpartum:</h3>

      <ul>
        <li>Rücken- und Beckenbodenschmerzen (Steissbein)</li>
        <li>Verstopfung, Übelkeit und Sodbrennen</li>
        <li>Narbenbehandlung (Kaiserschnitt, Episiotomie)</li>
        <li>Stillbeschwerden</li>
        <li>Rektusdiastase und Blasensenkung</li>
      </ul>

      <p>Die Behandlung verläuft parallel zu den gynäkologischen Routinekontrollen und der
      Hebammensprechstunde.</p>
      `
  },
  {
    id: '5',
    subcategory: '5',
    content: `<p>Eine kurze Regenerationszeit nach einer Verletzung ist für Sporttreibende und Kunstschaffende essentiell. Mit gezielten und schonenden Techniken lösen wir Blockaden
      und unterstützen den Heilungsprozess in den Gelenken. Eine Haltungs- und
      Bewegungsanalyse dient der Prävention von Schmerzen und reduziert die Einnahme von
      Medikamenten. Weitere Therapieziele (Ernährungsberatung, Physiotherapie) beziehen wir
      in die Behandlung mit ein.</p>

      <h3>Häufige Konsultationsgründe:</h3>
      <ul>
        <li>Verletzungen (Bänderzerrung, Luxation, Muskelfaserriss)</li>
        <li>Sehnenscheidenentzündungen (Tennisellenbogen, Adduktoren)</li>
        <li>Überbelastung (Nacken, Knie, Schulter)</li>
        <li>Schleudertrauma, Schwindel (Fahrrad, Reiten)</li>
        <li>Leistungssteigernde Beratung</li>
      </ul>      
      `
  },
  {
    id: '6',
    subcategory: '6',
    content: `<p>Viele Menschen knirschen oder pressen in der Nacht unbewusst mit den Zähnen. Ein erstes
      Indiz für Kieferverspannungen sind Kopfschmerzen am Morgen. Mit gezielten Mobilisationstechniken lösen wir Blockaden im Kopf- und Nackenbereich sowie Spannungen in der Kaumuskulatur. Auch bei Migräne, Spannungskopfschmerzen oder Blockaden der
      Halswirbelsäule. Weitere Therapien wie Knirschschiene, Ernährungsumstellung oder
      Botoxtherapie werden ebenfalls besprochen.</p>

      <h3>Mögliche Konsultationsgründe für Osteopathie im Kopfbereich:</h3>

      <ul>
        <li>Schwindel, Gleichgewichtsstörungen</li>
        <li>Kopfschmerzen, Migräne</li>
        <li>Tinnitus</li>
        <li>Hörsturz</li>
        <li>Konzentrationsstörungen, Benommenheit</li>
      </ul>      

      <img src="/assets/images/3.jpg"/>
      `
  },
  {
    id: '7',
    subcategory: '7',
    content: `<p style="margin-bottom: 35px">Zur ca. stündigen Erstkonsultation nehmen Sie Ihre medizinischen Berichte mit. 
      Wir erörterndie Ursache Ihrer Symptome und suchen nach einer individuellen Lösung für Sie. 
      Im ausführlichen Patientengespräch erstellen wir eine Diagnose. Auf Basis der medizinischen
      Untersuchung und osteopathischen Beweglichkeitstests entscheiden wir, welche manuellen
      Techniken sich am besten eignen. Gemeinsam definieren wir Ihren Behandlungsplan.</p>

      <img src="/assets/images/4.jpg"/>
      `
  },
  {
    id: '8',
    subcategory: '8',
    content: `<p style="margin-bottom: 35px">Je nach Beschwerde können sanfte Mobilisationstechniken des Gewebes oder mit Ihrem
      Einverständnis auch mal ein befreiendes «Knacksen» helfen. Bei akuten Schmerzen werden
      im Durchschnitt drei bis fünf Behandlungen benötigt. Bei chronischen Schmerzen erstellen
      wir einen individuellen Behandlungsplan und vernetzen uns mit anderen Fachpersonen.
      </p>

      <img src="/assets/images/5.jpg"/>
      <img src="/assets/images/6.jpg"/>
      `
  },
  {
    id: '9',
    subcategory: '9',
    content: `<p>Wir arbeiten mit den Ressourcen Ihres Körpers und Ihrer Psyche, um Ihre Gesundheit zu
      stärken. Für eine nachhaltige Behandlung zeigen wir Ihnen unterstützende Übungen bzw.
      Kräftigungsübungen, sowie Möglichkeiten zur Stressreduktion oder Ernährungsanpassungen.
      Die interdisziplinäre Zusammenarbeit mit weiteren Therapieformen fördern wir aktiv.</p>
      `
  },
  {
    id: '10',
    subcategory: '10',
    content: `<p>Wir sind schweizweit anerkannte <a target="_blank" href="https://www.gdk-cds.ch/de/gesundheitsberufe/osteopathie">GDK Osteopathen:innen</a> 
      mit Akkreditierung im <a target="_blank" href="https://www.svo-fso.ch/de_CH/">Schweizerischen Osteopathieverband (SuisseOsteo)</a>. Damit werden die Osteopathie-
      Behandlungen von allen Komplementär- und Zusatzversicherungen gemäss Ihrer Police
      anteilig rückvergütet mit Ausnahme der Helsana-Gruppe. Erkundigen Sie sich über die 
      Kostengutsprache bei Ihrer Krankenkasse vor der ersten Konsultation und bei einem Unfall 
      bei Ihrer Versicherung.</p>
      `
  },
  {
    id: '11',
    subcategory: '11',
    content: `<p>Osteopathie Meilen wendet den Tarif 590 an, gemäss Zusatzversicherungsgesetz (VVG).
      Die Verrechnung der Leistung erfolgt nach Aufwand zum 5 Minuten Tarif von 15 CHF.
      
      Eine Erstkonsultation dauert ca. eine Stunde und wird nach Aufwand mit ca. 180 CHF
      verrechnet. Eine Folgekonsultation dauert ca. 45 Minuten und wird zu 150 CHF verrechnet.
      
      Zur verrechneten Leistung gehören Anamnese, Diagnosestellung, Behandlung,
      Patientendokumentation, Aktenstudium und das Verfassen und Beantworten von
      medizinischen Berichten und Gesundheitsfragebogen.</p>
      `
  }
];

export const mockedCarouselSvgs = [
  {
    id: "1",
    alt: "Home",
    svg: <SvgPicture1 />,
  },
  {
    id: "2",
    alt: "Angebote",
    svg: <SvgPicture2 />,
  },
  {
    id: "3",
    alt: "Ihre_Behandlung",
    svg: <SvgPicture3 />,
  },
  {
    id: "4",
    alt: "Uber_mich",
    svg: <SvgPicture4 />,
  },
  {
    id: "5",
    alt: "Kontakt",
    svg: <SvgPicture5 />,
  },
];
