import clsx from "clsx";

import { Typography } from "@mui/material";

import { paragraphStyles } from "./Paragraph.styles";

export function Paragraph({ children, variant, component = "p", className }) {
  const classes = paragraphStyles();

  return (
    <Typography
      variant={variant}
      component={component}
      className={clsx(classes.paragraph, className && className)}
    >
      {children}
    </Typography>
  );
}
