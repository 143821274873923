import { createTheme } from "@mui/material/styles";
import THEME from "./theme-tokens.json";

import AvenirNextMedium from "./fonts/AvenirNextMedium/AvenirNextMedium.woff2";

const theme = createTheme({
  props: {
    MuiTypography: {
      variantMapping: {
        h1: "h1",
        h2: "h2",
        h3: "h3",
        h4: "h4",
        h5: "h5",
        h6: "h6",
        subtitle1: "p",
        subtitle2: "p",
        body1: "span",
        body2: "span",
        h6Alternative: "p",
        smallTitle: "h1",
      },
    },
  },
  breakpoints: {
    values: {
      smartphone: THEME.breakpoints.smartphone,
      tablet: THEME.breakpoints.tablet,
      desktop: THEME.breakpoints.desktop,
    },
  },
  palette: {
    primary: {
      main: THEME.palette.primary.main,
      light: THEME.palette.primary.light,
      dark: THEME.palette.primary.dark,
      hoveredLink: THEME.palette.primary.hoveredLink,
      visitedLink: THEME.palette.primary.visitedLink,
    },
    secondary: {
      main: THEME.palette.secondary.main,
      light: THEME.palette.secondary.light,
      blob: THEME.palette.secondary.blob,
    },
  },
  typography: {
    allVariants: {
      fontFamily: "AvenirNextMedium",
      fontStyle: THEME.typography.allVariants.fontStyle,
      fontWeight: THEME.typography.allVariants.fontWeight,
      fontVariantNumeric: "oldstyle-nums",

      "& a": {
        textDecoration: THEME.typography.allVariants.links.textDecoration,
        color: THEME.palette.primary.dark,
        transition: `color ease 0.4s`,
        "&:hover": {
          color: THEME.palette.primary.hoveredLink,
        },
      },
    },
    h1: {
      fontSize: THEME.typography.h1.fontSize,
      lineHeight: THEME.typography.h1.lineHeight,
      letterSpacing: THEME.typography.h1.letterSpacing,
    },
    body1: {
      fontSize: THEME.typography.body1.fontSize,
      lineHeight: THEME.typography.body1.lineHeight,
      letterSpacing: THEME.typography.body1.letterSpacing,
      paragraphSpacing: THEME.typography.body1.paragraphSpacing,
      opacity: THEME.typography.body1.opacity,
    },
    smallTitle: {
      fontSize: THEME.typography.smallTitle.fontSize,
      fontWeight: THEME.typography.smallTitle.fontWeight,
      lineHeight: THEME.typography.smallTitle.lineHeight,
      letterSpacing: THEME.typography.smallTitle.letterSpacing,
      textAlign: THEME.typography.smallTitle.textAlign,
    },
    body2: {
      fontWeight: THEME.typography.body2.fontWeight,
      fontSize: THEME.typography.body2.fontSize,
      lineHeight: THEME.typography.body2.lineHeight,
      letterSpacing: THEME.typography.body2.letterSpacing,
    },
    subtitle1: {
      fontSize: THEME.typography.subtitle1.fontSize,
      lineHeight: THEME.typography.subtitle1.lineHeight,
      letterSpacing: THEME.typography.subtitle1.letterSpacing,
    },
    caption: {
      fontWeight: THEME.typography.caption.fontWeight,
      fontSize: THEME.typography.caption.fontSize,
      lineHeight: THEME.typography.caption.lineHeight,
      letterSpacing: THEME.typography.caption.letterSpacing,
    },
    button: {
      fontWeight: THEME.typography.button.fontWeight,
      fontSize: THEME.typography.button.fontSize,
      lineHeight: THEME.typography.button.lineHeight,
      letterSpacing: THEME.typography.button.letterSpacing,
      textTransform: THEME.typography.button.textTransform,
    },
  },
  transitions: {
    fade: {
      imagesTimeout: THEME.transitions.fade.imagesTimeout,
      textsTimeout: THEME.transitions.fade.textsTimeout,
      overlayTimeout: THEME.transitions.fade.overlayTimeout,
      carouselImageInTimeout: THEME.transitions.fade.carouselImageInTimeout,
      carouselImageOffTimeout: THEME.transitions.fade.carouselImageOffTimeout,
    },
    slide: {
      textsTimeout: THEME.transitions.fade.textsTimeout,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          opacity: 1;
          color: rgb(52 52 52);
          background-color: ${THEME.palette.secondary.light};
        }
        body::-webkit-scrollbar {
          width: 0px;
        }
          
        body::-webkit-scrollbar-track {
          background-color: rgba(0, 0, 0, 0);
        }
         
        body::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0);
        }
        @font-face {
          font-family: 'AvenirNextMedium';
          font-style: normal;
          font-weight: 400;
          src: local('AvenirNextMedium'), url(${AvenirNextMedium}) format('woff2');
        }
        
      `,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "unset",
          },
        },
      },
    },
  },
});

export default theme;
