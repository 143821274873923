import { forwardRef } from "react";

import ClearIcon from "@mui/icons-material/Clear";

import { closeButtonStyles } from "./CloseButton.styles";

export const CloseButton = forwardRef((props, ref) => {
  const classes = closeButtonStyles();

  return (
    <div className={classes.close_button__root}>
      <ClearIcon ref={ref} onClick={props.onClick} />
    </div>
  );
});
