import { makeStyles } from "@mui/styles";

export const smartphoneHomeStyles = makeStyles(() => ({
  smartphone_home__root: {
    // height: "100vh",
    // width: "100vw",
    inset: "0",
    position: "fixed",
    overflow: "hidden",
  },

  smartphone_home__logo_container: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  
  smartphone_home__navigation_container: {
    position: "fixed",
    bottom: 32,
    left: "50%",
    right: "50%",
    zIndex: 10,
  },
}));
