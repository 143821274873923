import { makeStyles } from "@mui/styles";

export const overlayInnerStyles = makeStyles(() => ({
  overlay_inner__root: {
    position: "absolute",
    width: "auto",
    height: "100vh",
    display: "flex",
    alignItems: "center",

    left: "50vw",
    transform: "translateX(-50%)",

    "& svg": {
      width: "auto",
      height: "80vh",
    },
  },
}));
