import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { tokensAccordionStyles } from "./TokensAccordion.styles";
import { ColorField } from "features/UI/atoms/theme-editor/inputs/ColorField";
import theme from "mui/theme";

export function TokensAccordion() {
  const [expanded, setExpanded] = React.useState(false);

  const classes = tokensAccordionStyles();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.tokens_accordion__root}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="subtitle1" sx={{ width: "33%", flexShrink: 0 }}>
            Palette
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.tokens_accordion__inputRow}>
            <ColorField color={theme.palette.primary.main} />
            <Typography variant="body1">Primary main</Typography>
          </div>
          <div className={classes.tokens_accordion__inputRow}>
            <ColorField color={theme.palette.primary.light} />
            <Typography variant="body1">Primary light</Typography>
          </div>
          <div className={classes.tokens_accordion__inputRow}>
            <ColorField color={theme.palette.primary.dark} />
            <Typography variant="body1">Primary dark</Typography>
          </div>
          <div className={classes.tokens_accordion__inputRow}>
            <ColorField color={theme.palette.primary.hoveredLink} />
            <Typography variant="body1">Primary hoveredLink</Typography>
          </div>
          <div className={classes.tokens_accordion__inputRow}>
            <ColorField color={theme.palette.primary.visitedLink} />
            <Typography variant="body1">Primary visitedLink</Typography>
          </div>
        </AccordionDetails>
        <AccordionDetails>
          <div className={classes.tokens_accordion__inputRow}>
            <ColorField color={theme.palette.secondary.main} />
            <Typography variant="body1">Secondary main</Typography>
          </div>
          <div className={classes.tokens_accordion__inputRow}>
            <ColorField color={theme.palette.secondary.light} />
            <Typography variant="body1">Secondary light</Typography>
          </div>
          <div className={classes.tokens_accordion__inputRow}>
            <ColorField color={theme.palette.secondary.blob} />
            <Typography variant="body1">Secondary blob</Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography variant="subtitle1" sx={{ width: "33%", flexShrink: 0 }}>
            Typography
          </Typography>
        </AccordionSummary>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography variant="subtitle1" sx={{ width: "33%", flexShrink: 0 }}>
            Transitions
          </Typography>
        </AccordionSummary>
      </Accordion>
    </div>
  );
}
