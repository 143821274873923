import { ReactComponent as BlobAnimation } from "./assets/blob-animation.svg";

import { Typography } from "@mui/material";

import { blobStyles } from "./Blob.styles";

export function Blob() {
  const classes = blobStyles();

  return (
    <div className={classes.blob__root}>
      <div className={classes.blob__button}>
        <a
          target="_blank"
          rel="nofollow"
          href="https://www.onedoc.ch/de/osteopathin/meilen/pb6zx/barbara-tischhauser"
        >
          <div className={classes.blob__text_container}>
            <Typography variant="body1">Termine</Typography>
          </div>

          <BlobAnimation title="animated blob" />
        </a>
      </div>
    </div>
  );
}
