import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Fade } from "@mui/material";

import { listenEventBroker } from "features/event-driver/broker/eventBrokerSlice";

import { overlayInnerStyles } from "./OverlayInner.styles";

import THEME from "mui/theme-tokens.json";
const token_overlayTimeout = THEME.transitions.fade.overlayTimeout;

export function OverlayInner(props) {
  const classes = overlayInnerStyles();

  const eventBroker = useSelector(listenEventBroker);
  const image = props.image || {};

  const [overlayTimeout] = useState(token_overlayTimeout);
  const [visible, visibleSet] = useState(false);

  useEffect(() => {
    visibilityToogle();
  }, [eventBroker.eventValue]);

  const visibilityToogle = () => {
    if (eventBroker.eventSource === "MenuItem") {
      if (eventBroker.eventType === "hover") {
        if (eventBroker.eventValue === image.id) {
          visibleSet(true);
          return;
        }
      }
    }
    visibleSet(false);
    return;
  };

  return (
    <Fade timeout={overlayTimeout} in={visible}>
      <div className={classes.overlay_inner__root}>{image.svg}</div>
    </Fade>
  );
}
