import { forwardRef } from "react";

import { submitButtonStyles } from "./SubmitButton.styles";
import { Button } from "@mui/material";

export const SubmitButton = forwardRef((props, ref) => {
  const classes = submitButtonStyles();

  return (
    <div className={classes.submit_button__root} ref={ref}>
      <Button onClick={props.onClick}>SAVE THEME</Button>
    </div>
  );
});
