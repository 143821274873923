import { makeStyles } from "@mui/styles";
import theme from "mui/theme";

export const menuItemStyles = makeStyles(() => ({
  menu_item__root: {
    cursor: "pointer",
    color: theme.palette.primary.main,

    transition: "color ease 0.5s",
    "&:hover": {
      color: theme.palette.primary.dark,
    },

    marginBottom: 10,
  },

  menu_item__active: {
    color: theme.palette.primary.dark,
  },

  menu_item__condensed: {
    color: theme.palette.primary.dark,
  },
}));
