import { makeStyles } from "@mui/styles";
import THEME from "mui/theme-tokens.json";

export const titleLikeBlockStyles = makeStyles(() => ({
  title_like_block__root: {
    width: THEME.spacing.contentColumnMaxWidth.desktop,
    maxWidth: THEME.spacing.contentColumnMaxWidth.desktop,
  },

  title_like_block__large: {
    paddingRight: "unset",
  },

  title_like_block__hidden: {
    display: "none",
  },
}));
