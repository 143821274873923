import { useDispatch } from "react-redux";
import {
  updateSelectedCategory,
  updateSelectedSubcategory,
} from "features/event-driver/broker/eventBrokerSlice";
import {
  updateCurrentPage,
  updateSmartphoneSection,
} from "features/event-driver/navigation/navigationSlice";

import { ReactComponent as LogoIcon } from "./assets/logo.svg";
import { ReactComponent as LogoSmallIcon } from "./assets/logo-small.svg";

import { logoStyles } from "./Logo.styles";

const TITLE = "Osteopathie Meilen";

export function Logo(props) {
  const classes = logoStyles();

  const smallVersion = props.small || null;

  const dispatch = useDispatch();

  function updateEventStore() {
    dispatch(updateSelectedCategory(""));
    dispatch(updateSelectedSubcategory(""));
    dispatch(updateCurrentPage("home"));
    dispatch(updateSmartphoneSection("home"));
  }

  return (
    <div className={classes.logo__root} onClick={() => updateEventStore()}>
      {!smallVersion ? (
        <LogoIcon title={TITLE} />
      ) : (
        <LogoSmallIcon title={TITLE} />
      )}
    </div>
  );
}
