import { useDispatch, useSelector } from "react-redux";

import {
  fireGenericEvent,
  listenStoredEvents,
  updateSelectedCategory,
  updateSelectedSubcategory,
} from "features/event-driver/broker/eventBrokerSlice";

import { Typography } from "@mui/material";

import { menuItemCategoryStyles } from "./MenuItemCategory.styles";
import { updateSmartphoneSection } from "features/event-driver/navigation/navigationSlice";

export function MenuItemCategory({ id, children }) {
  const classes = menuItemCategoryStyles();
  const dispatch = useDispatch();

  const storedEvents = useSelector(listenStoredEvents);
  const selectedCategory = storedEvents.selectedCategory;

  const fireHoverEvent = (action) => {
    let eventValue = id;
    if (action === "onMouseLeave") eventValue = "";
    dispatch(
      fireGenericEvent({
        eventSource: "MenuItem",
        eventType: "hover",
        eventValue: eventValue,
      })
    );
    return;
  };

  const fireClickEvent = () => {
    dispatch(updateSelectedCategory(id));
    dispatch(updateSmartphoneSection("subcategory"));
    //cleanup
    dispatch(updateSelectedSubcategory(""));

    return;
  };

  return (
    <div
      onMouseEnter={() => fireHoverEvent("onMouseEnter")}
      onMouseLeave={() => fireHoverEvent("onMouseLeave")}
      onClick={() => fireClickEvent()}
      className={selectedCategory === id ? classes.menu_item__active : ""}
    >
      <Typography variant="h1" component="p">
        {children}
      </Typography>
    </div>
  );
}
