import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { listenDataStore } from "features/event-driver/store/dataStoreSlice";
import { findElementPositionById } from "features/utils/DOM/findElementPositionById";
import {
  listenStoredEvents,
  updateSelectedMenuByScrollNavigation,
} from "features/event-driver/broker/eventBrokerSlice";

import { contentConstructorStyles } from "./ContentConstructor.styles";

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
  window.addEventListener(
    "wheel",
    null,
    Object.defineProperty({}, "passive", {
      get: function () {
        supportsPassive = true;
      },
    })
  );
} catch (e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;

export function ContentConstructor(props) {
  const classes = contentConstructorStyles();

  const dispatch = useDispatch();
  const { desktop, tablet } = props;

  const storedEvents = useSelector(listenStoredEvents);
  const selectedSubcategory = storedEvents.selectedSubcategory;

  const storedData = useSelector(listenDataStore);
  const contents = storedData.subcategoriesContent;

  const [content, contentSet] = useState({});
  const [topPosition, topPositionSet] = useState(0);
  const [render, renderSet] = useState(false);
  const [shouldRenderFakeTopDiv, shouldRenderFakeTopDivSet] = useState(false);
  const [shouldRenderFakeBottomDiv, shouldRenderFakeBottomDivSet] =
    useState(false);

  const [loading, loadingSet] = useState(false);

  useEffect(() => {
    renderSet(false);

    document.body.setAttribute("style", "overflow: hidden");

    const renderTimer = setTimeout(() => {
      findContentToRender();
    }, 350);

    // const whellEventTimer = setTimeout(() => {
    //   enableScroll();
    // }, 900);

    return () => {
      clearTimeout(renderTimer);
    };
  }, [selectedSubcategory]);

  function findContentToRender() {
    const currentContent = contents.filter(
      (el) => el.subcategory === selectedSubcategory
    );

    contentSet(currentContent[0]);
    loadingSet(false);

    return;
  }

  useEffect(() => {
    controlTopPosition();
    determineFakeBottomDivNecessity();

    renderSet(true);
  }, [content]);

  function controlTopPosition() {
    const subcategoryMenuItemElement = findElementPositionById(
      `menuItem-subcategory-${selectedSubcategory}`
    );

    if (subcategoryMenuItemElement) {
      topPositionSet(subcategoryMenuItemElement.top);
    }

    if (content.id !== "1" && content.id !== "7") {
      shouldRenderFakeTopDivSet(true);
      window.scrollTo(0, 30);
    } else {
      shouldRenderFakeTopDivSet(false);
      window.scrollTo(0, 0);
    }

    return;
  }

  function determineFakeBottomDivNecessity() {
    if (content.id !== "6" && content.id !== "11") {
      shouldRenderFakeBottomDivSet(true);
    } else {
      shouldRenderFakeBottomDivSet(false);
    }

    return;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      document.body.setAttribute("style", "overflowY: scroll");
    }, 1000);

    return () => clearTimeout(timer);
  }, [selectedSubcategory]);

  useEffect(() => {
    window.addEventListener("scroll", controlScroller, false);
  });

  function controlScroller() {
    if (document.body.style.overflow === "hidden") {
      return;
    }
    const divElement = document.getElementById(`content-${content.id}`);

    if (!divElement) return;

    const bottomPosition = divElement.getBoundingClientRect().bottom;

    const subcategoryMenuElement = document.getElementById(
      `menuItem-subcategory-${selectedSubcategory}`
    );

    if (!subcategoryMenuElement) return;

    let triggerPosition = 0;

    if (subcategoryMenuElement) {
      triggerPosition = subcategoryMenuElement.getBoundingClientRect().top;
    }

    if (bottomPosition > 0 && bottomPosition < triggerPosition && !loading) {
      loadingSet(true);
    }
    if (window.scrollY <= 0 && content.id !== "1" && content.id !== "7") {
      loadingSet(true);
    }
  }

  useEffect(() => {
    if (loading) {
      navigateToNextContent();
    }
  }, [loading]);

  async function navigateToNextContent() {
    await Promise.all([dispatchTrigger(400)]);
  }

  function dispatchTrigger(ms) {
    if (window.scrollY <= 0) {
      dispatch(updateSelectedMenuByScrollNavigation("previous"));
      return;
    }
    dispatch(updateSelectedMenuByScrollNavigation("next"));
    return new Promise(() =>
      setTimeout(() => {
        loadingSet(false);
      }, ms)
    );
  }

  function calcBottomFakeDivHeight() {
    const subcategoryMenuElement = document.getElementById(
      `menuItem-subcategory-${selectedSubcategory}`
    );

    let screenHeight = window.innerHeight;

    if (!subcategoryMenuElement) return screenHeight;

    const menuElement = subcategoryMenuElement.getBoundingClientRect();

    const menuElementHeight =
      subcategoryMenuElement.getBoundingClientRect().height;

    let finalSpaceNeddedForNavigate = screenHeight - menuElement.top;

    if (selectedSubcategory === "6" || selectedSubcategory === "11") {
      finalSpaceNeddedForNavigate -= menuElementHeight;
    } else {
      finalSpaceNeddedForNavigate += 60;
    }

    return finalSpaceNeddedForNavigate;
  }

  return (
    <div
      className={clsx(
        classes.content_constructor__root,
        tablet
          ? classes.content_constructor__tablet
          : classes.content_constructor__desktop
      )}
    >
      {content && (
        <div
          className={classes.content_constructor__content_wrapper}
          key={`content-wrapper-${content.id}`}
          style={{
            top: topPosition,
            opacity: render ? 1 : 0,
          }}
        >
          {shouldRenderFakeTopDiv && <div style={{ height: "30px" }} />}

          <div
            id={`content-${content.id}`}
            dangerouslySetInnerHTML={{
              __html: content.content,
            }}
          ></div>

          <div
            id="fakeBottomDiv"
            style={{ height: calcBottomFakeDivHeight() }}
          />
        </div>
      )}
    </div>
  );
}
