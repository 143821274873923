import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentPage } from "features/event-driver/navigation/navigationSlice";

import { Fade } from "@mui/material";

import { Home } from "features/UI/pages/home/Home";
import { Content } from "features/UI/pages/content/Content";

import theme from "mui/theme";
import { Blob } from "features/UI/atoms/svgs/blob/Blob";

import { checkCurrentPath } from "features/utils/safety/routes";
import ThemeEditor from "features/UI/organisms/theme-editor/ThemeEditor";

import { appStyles } from "./App.styles";

const timeout = theme.transitions.fade.imagesTimeout;

function App() {
  const classes = appStyles();
  const renderThemeEditor = checkCurrentPath("/theme");

  const currentPage = useSelector(selectCurrentPage);

  const [pageRenderer, pageRendererSet] = useState();

  useEffect(() => {
    pageRendererSet(ActualPageComponent);
  }, [currentPage]);

  const ActualPageComponent = () => {
    switch (currentPage) {
      case "home":
        return <Home />;
      case "content":
        return <Content />;
      default:
        return <Content />;
    }
  };

  return (
    <div id="app-root" className={classes.app__root}>
      {renderThemeEditor && <ThemeEditor />}
      {pageRenderer}
      <Fade in timeout={timeout}>
        <div className={classes.blob__container}>
          <Blob />
        </div>
      </Fade>
    </div>
  );
}

export { App };
