import { forwardRef, useEffect } from 'react';
import clsx from 'clsx';

import { Menu } from 'features/UI/organisms/menu/Menu';
import { MenuItem } from 'features/UI/molecules/menu-item/MenuItem';
import { ContentWrapper as ContentWrapperStyles } from 'features/UI/molecules/content/wrapper/text-block/ContentWrapper';

import { Slide } from '@mui/material';
import { sectionSubcategoryStyles } from './SectionSubcategory.styles';

import THEME from 'mui/theme-tokens.json';
import { SectionTitle } from '../title/SectionTitle';
const token_textsTimeout = THEME.transitions.slide.textsTimeout;

export const SectionSubcategory = forwardRef((props, ref) => {
  const classes = sectionSubcategoryStyles();

  const slideTimeout = token_textsTimeout;

  const { categories, selectedCategory, subcategories, sectionSlide } = props;

  const sectionTitle = categories?.find(
    (category) => category.id === selectedCategory
  );

  useEffect(() => {
    if (subcategories.length > 0 && sectionSlide === 2) {
      if (subcategories[0].content) {
        const rootEl = document.getElementById('smartphone-content-root');
        const carouselEl = document
          .getElementsByClassName('slider animated')
          .item(0);
        props.enableScroll(rootEl, carouselEl);
      }
    }
  }, [subcategories.length, subcategories, sectionSlide]);

  return (
    <div
      className={clsx(classes.section_subcategory__root)}
      ref={ref}
      id="subcategory-root"
    >
      <SectionTitle>{sectionTitle?.title}</SectionTitle>

      {subcategories.length > 0 ? (
        !subcategories[0].content ? (
          <Menu className={classes.section_subcategory__menu}>
            {subcategories &&
              subcategories.map((subcategory) => (
                <MenuItem variant="subcategory" id={subcategory.id}>
                  {subcategory.title}
                </MenuItem>
              ))}
          </Menu>
        ) : (
          <ContentWrapperStyles smartphone>
            <div
              dangerouslySetInnerHTML={{
                __html: subcategories[0].content
              }}
            ></div>
          </ContentWrapperStyles>
        )
      ) : null}
    </div>
  );
});
