import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { mockedSubcategories } from "design-spec/mock";

import { listenStoredEvents } from "features/event-driver/broker/eventBrokerSlice";
import { listenDataStore } from "features/event-driver/store/dataStoreSlice";

import { DesktopContent } from "features/UI/templates/desktop/content/DesktopContent";
import { TabletContent } from "features/UI/templates/tablet/content/TabletContent";
import { SmartphoneContent } from "features/UI/templates/smartphone/content/SmartphoneContent";

import { useMediaQuery } from "@mui/material";

import theme from "mui/theme";

import { contentStyles } from "./Content.styles";

export function Content() {
  const classes = contentStyles();

  const desktopScreen = useMediaQuery(theme.breakpoints.only("desktop"));
  const tabletScreen = useMediaQuery(theme.breakpoints.only("tablet"));
  const smartphoneScreen = useMediaQuery(theme.breakpoints.only("smartphone"));

  const storedEvents = useSelector(listenStoredEvents);
  const selectedCategory = storedEvents.selectedCategory;
  const selectedSubcategory = storedEvents.selectedSubcategory;

  const storedData = useSelector(listenDataStore);
  const categories = storedData.categories;
  const contents = storedData.subcategoriesContent;

  const [subcategories, subcategoriesSet] = useState([]);

  useEffect(() => {
    const filteredSubcategories = mockedSubcategories.filter(
      (sub) => sub.category === selectedCategory
    );
    subcategoriesSet(filteredSubcategories);
  }, [selectedCategory]);

  const cmsData = {
    categories,
    subcategories,
    selectedCategory,
    selectedSubcategory,
    contents,
  };

  return (
    <div className={classes.content__root}>
      {desktopScreen && <DesktopContent cmsData={cmsData} />}
      {tabletScreen && <TabletContent cmsData={cmsData} />}
      {smartphoneScreen && <SmartphoneContent cmsData={cmsData} />}
    </div>
  );
}
