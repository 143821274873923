import { useDispatch } from 'react-redux';
import { updateCurrentPage } from 'features/event-driver/navigation/navigationSlice';

import { TextBlock } from 'features/UI/molecules/content/text-block/TextBlock';
import { SectionCategory } from 'features/UI/organisms/smartphone-sections/category/SectionCategory';
import { SectionSubcategory } from 'features/UI/organisms/smartphone-sections/subcategory/SectionSubcategory';
import { SectionContent } from 'features/UI/organisms/smartphone-sections/content/SectionContent';
import { Carousel } from 'react-responsive-carousel';

import { smartphoneContentStyles } from './SmartphoneContent.styles';

import { mockedDefaultContentBlock } from 'design-spec/mock';
import { Navigation } from 'features/UI/organisms/navigation/Navigation';
import { Previous } from 'features/UI/molecules/navigation/buttons/previous/Previous';
import { Next } from 'features/UI/molecules/navigation/buttons/next/Next';
import { useEffect, useState } from 'react';
import {
  startTouch,
  detectMoveTouchDirection
} from 'features/utils/events/touchEvents';

export function SmartphoneContent({ cmsData }) {
  const classes = smartphoneContentStyles();

  const dispatch = useDispatch();

  const {
    selectedCategory,
    selectedSubcategory,
    categories,
    subcategories,
    contents
  } = cmsData;

  const currentContent = contents.find(
    (content) => content.subcategory === selectedSubcategory
  );

  const [sectionSlide, sectionSlideSet] = useState(0);

  function onClickNextButton() {
    composeCarousel();

    const timer = setTimeout(() => sectionSlideSet(sectionSlide + 1), 150);
    return () => clearTimeout(timer);
  }

  function onClickPrevButton() {
    const rootEl = document.getElementById('smartphone-content-root');
    const carouselEl = document
      .getElementsByClassName('slider animated')
      .item(0);
    const fadeOut = rootEl.style.overflow === 'auto';

    if (fadeOut) {
      rootEl.style.opacity = '0';
    }

    setTimeout(
      () => {
        rootEl.scrollTo(0, 0);

        if (sectionSlide === 0) {
          dispatch(updateCurrentPage('home'));
          return;
        }

        sectionSlideSet(sectionSlide - 1);
      },
      fadeOut ? 650 : 0
    );

    if (fadeOut) {
      setTimeout(() => {
        rootEl.style.opacity = '1';
        disableScroll(rootEl, carouselEl);
      }, 1000);
    }
  }

  const [carouselComposition, carouselCompositionSet] = useState([]);

  useEffect(() => {
    composeCarousel();
  }, [selectedCategory, selectedSubcategory, sectionSlide]);

  function composeCarousel() {
    const firstSection = (
      <TextBlock>{mockedDefaultContentBlock('smartphone')}</TextBlock>
    );

    const categoriesSection = (
      <div onClick={() => onClickNextButton()}>
        <SectionCategory
          categories={categories}
          selectedCategory={selectedCategory}
        />
      </div>
    );

    const subcategoriesSection = (
      <div onClick={() => onClickNextButton()}>
        <SectionSubcategory
          enableScroll={enableScroll}
          sectionSlide={sectionSlide}
          categories={categories}
          selectedCategory={selectedCategory}
          subcategories={subcategories}
          selectedSubcategory={selectedSubcategory}
        />
      </div>
    );

    const contentSection = (
      <SectionContent
        selectedCategory={selectedCategory}
        categories={categories}
        subcategories={subcategories}
        selectedSubcategory={selectedSubcategory}
        content={currentContent}
      />
    );
    if (!selectedCategory) {
      carouselCompositionSet([firstSection, categoriesSection]);
    } else if (!selectedSubcategory) {
      carouselCompositionSet([
        firstSection,
        categoriesSection,
        subcategoriesSection
      ]);
    } else {
      carouselCompositionSet([
        firstSection,
        categoriesSection,
        subcategoriesSection,
        contentSection
      ]);
    }
  }

  let timeoutId = 0;
  function enableScroll(rootEl, carouselEl) {
    clearTimeout(timeoutId);

    setTimeout(() => {
      rootEl.style.overflow = 'auto';
      rootEl.ontouchstart = (e) => startTouch(e.touches[0]);
      rootEl.ontouchmove = (e) => handleTouchMove(e);

      if (carouselEl) {
        carouselEl.style.pointerEvents = 'none';
      }
    }, 500);
  }

  function disableScroll(rootEl, carouselEl) {
    rootEl.style.overflow = 'hidden';
    rootEl.ontouchstart = '';
    rootEl.ontouchmove = '';

    if (carouselEl) {
      carouselEl.style.pointerEvents = 'all';
    }
  }

  function slideHasChange(slide) {
    sectionSlideSet(slide);
    controlNavButtonsVisibility();

    const rootEl = document.getElementById('smartphone-content-root');
    const carouselEl = document
      .getElementsByClassName('slider animated')
      .item(0);

    if (slide === 3) {
      enableScroll(rootEl, carouselEl);
    }
  }

  function handleTouchMove(e) {
    const moveDirection = detectMoveTouchDirection(e.changedTouches[0]);
    if (moveDirection === 'right') {
      onClickPrevButton();
    }
  }

  const [shouldHideNextButton, shouldHideNextButtonSet] = useState(false);

  function controlNavButtonsVisibility() {
    if (sectionSlide === 0) {
      shouldHideNextButtonSet(false);
      return;
    }

    if (sectionSlide === 1 && selectedCategory !== '') {
      shouldHideNextButtonSet(false);
      return;
    } else {
      shouldHideNextButtonSet(true);
    }

    if (sectionSlide === 2 && selectedSubcategory !== '') {
      shouldHideNextButtonSet(false);
      return;
    } else {
      shouldHideNextButtonSet(true);
    }

    if (sectionSlide === 3) {
      shouldHideNextButtonSet(true);
      return;
    }
  }

  function handleSwipeEnd(e) {
    const direction = detectMoveTouchDirection(e.changedTouches[0]);

    /*
      If you're on the last slide and swipe right
    */
    if (direction === 'right') {
      if (sectionSlide - 1 === -1) {
        const previousButtonElement =
          document.getElementById('previous-button');

        previousButtonElement.click();
      }
    }
  }

  return (
    <div
      className={classes.smartphone_content__root}
      id="smartphone-content-root"
    >
      <div className={classes.smartphone_content__text_container}>
        <Carousel
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          selectedItem={sectionSlide}
          onSwipeStart={(e) => startTouch(e.targetTouches[0])}
          onSwipeEnd={handleSwipeEnd}
          onChange={(slide) => slideHasChange(slide)}
        >
          {carouselComposition.map((slide) => {
            return slide;
          })}
        </Carousel>
      </div>

      <div className={classes.section__navigation_container}>
        <Navigation>
          <Previous onClick={onClickPrevButton} />
          {!shouldHideNextButton && <Next onClick={onClickNextButton} />}
        </Navigation>
      </div>
    </div>
  );
}
