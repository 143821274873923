import { makeStyles } from "@mui/styles";

export const overlayStyles = makeStyles(() => ({
  overlay__root: {
    position: "fixed",
    top: 0,
    right: 0,
    width: "100%",
    height: "100vh",

    pointerEvents: "none",
  },
}));
